<template>
  <div class="h-screen" :class="isValidToken ? 'mt-0 p-2' : 'mt-12 p-2'">
    <div class=" bg-white rounded-xl" v-if="!isValidToken">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Payments ({{totalCount}})
        </div>
      </div>
      <div class="pt-1">
        <div class="mt-4 p-2 pb-0">
            <div class="flex flex-wrap ">
              <div class="w-64 mb-4" :class="mobileView ? 'w-64' : 'w-72'">
                <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Payment...`"/>
              </div>
            </div>
          </div>
          <div>
            <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="!mobileView">
              <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
                <div class="table_col w-52 uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''">
                  <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
                   <div v-if="titleObj[0].icon !== ''">
                      <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                      <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                    </div>
                </div>
                <div class="table_col w-52 uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''">
                  <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
                   <div v-if="titleObj[1].icon !== ''">
                      <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                      <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                    </div>
                </div>
                <div class="table_col w-44 uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''">
                  <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
                   <div v-if="titleObj[2].icon !== ''">
                      <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                      <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                    </div>
                </div>
                <div class="table_col w-44 uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''">
                  <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
                   <div v-if="titleObj[3].icon !== ''">
                      <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                      <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                    </div>
                </div>
              </div>
                <div v-if="paymentList.length > 0">
                <div v-for="(data, index) in paymentList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openPaymentDetails(data)">
                  <div class="table_col w-52 ">
                    <div class="flex items-center">
                    <span class="  text-gray4 heading-6">{{data.transactionId !== '' ? data.transactionId : '-'}}</span>
                    </div>
                  </div>
                  <div class="table_col w-52">
                    <div class="flex items-center">
                    <span class="  text-gray4 heading-6">{{data.paymentMethodName}}</span>
                    </div>
                  </div>
                  <div class="table_col w-44 ">
                    <div class="flex items-center">
                    <span class="  text-gray4 heading-6">{{data.paymentAmount | amountOnlyNumber}}</span>
                    </div>
                  </div>
                  <div class="table_col w-44 ">
                    <div class="flex items-center">
                    <span :style="`color: ${data.invoiceStatusColor};`" class=" text-gray4 heading-6">{{data.addedDate | formatForDatePicker}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="paymentList.length > 0 && mobileView">
              <div v-for="(data, index) in paymentList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showJobDetail(data)">
                <div class="">
                  <div class="flex items-center mb-1">
                    <span class="text-gray4 heading-4">{{data.paymentAmount | amountOnlyNumber}}</span>
                  </div>
                  <div class="flex items-center">
                    <p class="text-gray4 heading-6 w-32">Added Date: </p>
                    <span class="text-gray4 heading-5">{{data.addedDate | formatForDatePicker}}</span>
                  </div>
                  <div class="flex items-center">
                    <p class="text-gray4 heading-6 w-32">Payment Method: </p>
                    <span class="text-gray4 heading-5">{{data.paymentMethodName}}</span>
                  </div>
                  <div class="flex items-center">
                    <p class="text-gray4 heading-6 w-32">Transaction Id: </p>
                    <span class="text-gray4 heading-5">{{data.transactionId}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="paymentList.length === 0">
              <NoRecords :alertMessage="` No records found, please check filters on this page in case you did not find required data.`" />
            </div>
            <div>
            <Pagination
              :totalDataCount="totalCount"
              :resetPagination="resetPaginationValues"
              @callApiAsPerPagination="getDataFromPagination"/>
          </div>
          </div>
      </div>
    </div>
    <div v-if="isValidToken" class="popup_overlay">
        <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
          <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-4 md:px-8 lg:px-20 py-8 rounded-lg shadow-2xl">
              <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
              <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Please Check Your Link and Try Again Later</p>
          </div>
        </div>
      </div>
     <div v-if="isExpired">
        <EmailVarified />
     </div>
  </div>
</template>
<script>
// import StatusBtn from "@/View/components/statu-filter-btn.vue";
import SearchTextInput from '@/View/components/searchTextInput.vue'
// import Button from '@/View/components/globalButton.vue'
import MyJobApp from "@/api/MyJobApp.js";
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import EmailVarified from '@/View/endUserNewFlow/components/emailVarified.vue'
export default {
components: {
  ascIconSvg,
  NoRecords,
  descIconSvg,
  Pagination,
  SearchTextInput,
  EmailVarified,
  // Button,
  // StatusBtn,
},
props: [],
mixins: [deboucneMixin],
data () {
  return {
    mobileView: false,
    isValidToken: false,
    isExpired: false,
    sessionToken: '',
    ShowErr: false,
      totalCount: 0,
      resetPaginationValues: false,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        searchVal: '',
        isActive: true,
        sortBy: 'invoiceDate',
        statusList: []
      },
      paymentList: [],
      titleObj: [
        {title: 'Transaction Id', icon: '', sortName: ''},
        {title: 'Payment Method', icon: '', sortName: ''},
        {title: 'Amount', icon: '', sortName: ''},
        {title: 'Date', icon: '', sortName: ''},
      ] 
  }
},
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler()
  document.title = 'EndUser: Payment'
  this.sessionToken = this.$route.params.customerToken
   this.getListDeboucne = this.debounce(function () {
    this.resetPaginationValues = true
  }, 500)
  this.$root.$on('tokenResponse', (isTokenExpired, isTokenInvalid) => {
      if (!isTokenExpired && !isTokenInvalid) {
        this.getPaymentList()
      } else if (isTokenExpired && !isTokenInvalid) {
        this.isExpired = true
        this.isValidToken = false
        this.firstLoading = true
      } else if (!isTokenExpired && isTokenInvalid) {
        this.isExpired = false
        this.isValidToken = true
        this.firstLoading = true
      }
    })
    this.$root.$emit('tokenVarificationApi', this.$route.params.customerToken)
},
beforeDestroy() {
    this.$root.$off('tokenResponse')
  },
watch: {
},
methods: {
   getDataFromPagination (data) {
    this.filterObject.start = data.offset
    this.filterObject.length = data.limit
    
    this.getPaymentList(true)
  },
  sortByColumn () {},
  searchCall (data) {
    this.filterObject.searchVal = data
    this.getListDeboucne()
  },
  openPaymentDetails (data) {
    this.$router.push({name: 'EndUserPaymentDetail', params: {customerToken: this.sessionToken, paymentToken: data.paymentLinkhash}})
  },
  getPaymentList() {
    let isValid = true
    if (isValid) {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.GetEndUserPaymentList(
        this.sessionToken,
        this.filterObject.start,
        this.filterObject.length,
        this.filterObject.searchVal,
        '',
        (response) => {
          this.resetPaginationValues = false
          this.paymentList = response.Data.tableRow !== null ? response.Data.tableRow  : []
          this.totalCount = response.Data.count
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    }
  },
  resizeWindowHandler() {
      if (window.innerWidth < 800) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
    },
}
}
</script>
<style scoped>
.table_col {
overflow-wrap: anywhere;
white-space: pre-wrap;
padding-right: 8px;
}
</style>
